<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="下单次数导入" @closed="handleClosed">
    <div class="head-container">
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
      </el-upload>
      <el-button type="primary" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
    </div>
    <div class="v" v-if="sucInfo">
      <span>导入结果：共{{sucInfo.total}}条,成功{{sucInfo.success}}条,失败{{sucInfo.fail}}条</span>&nbsp;
      <template v-if="errList.length>0">
        <span>错误详情</span>
        <el-table :data="errList" border style="width: 100%;">
          <el-table-column prop="disName" label="经销商名称" :show-overflow-tooltip="true" />
          <el-table-column prop="disErpCode" label="编码" :show-overflow-tooltip="true" />
          <el-table-column prop="buyTimes" label="次数" />
          <el-table-column prop="err" label="错误信息" width="250" show-overflow-tooltip />
        </el-table>
      </template>
    </div>
  </el-dialog>
</template>
<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  data() {
    return {
      uploading: false,
      uploadUrl: config.hosts.request + "api/performanceBuyLimit/import",
      uploadHeaders: {},
      downloadLoading: false,
      dialog: false,
      errList: [],
      sucInfo: null,
      errInfo: null,
    };
  },
  computed: {
    btnName() {
      return this.uploading ? "导入中" : "选择文件";
    },
  },
  methods: {
    handleClosed() {
      this.$emit("change");
    },
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {
      this.sucInfo = res;
      this.errList = res.detail;
      this.uploading = false;
      if (res.sucCount && res.sucCount > 0) {
        this.$parent.init();
      }
    },
    handleUploadError(err) {
      this.errInfo = err;
      this.uploading = false;
    },
    downloadTemplate() {
      this.downloadLoading = true;
      download("api/performanceBuyLimit/template")
        .then((result) => {
          downloadFile(result, "下单次数模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    resetForm() {
      this.uploadHeaders.Authorization = "Bearer " + getToken();
      this.sucInfo = null;
      this.dialog = true;
    },
  },
};
</script>